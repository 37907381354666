<script>
import AdvancedTable from "../../../components/elements/advanced-table.vue";
import {mapGetters} from "vuex";

export default {
    components: {
        AdvancedTable,
    },

    props: {
        type: {
            type: String,
            default: 'all'
        }
    },

    computed: {
        ...mapGetters({
            dynamicFields: 'field/list',
            authenticated: 'user/authenticated',
            admin: 'auth/admin'
        }),

        fields: function () {
            const fields = [
                {
                    key: "code",
                    label: this.$t('partners::partners.columns.code').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "name",
                    label: this.$t('partners::partners.columns.name').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "registration_number",
                    label: this.$t('partners::partners.columns.registration_number').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "vat_number",
                    label: this.$t('partners::partners.columns.vat_number').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "email",
                    label: this.$t('partners::partners.columns.email').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "phone",
                    label: this.$t('partners::partners.columns.phone').ucFirst(),
                    thClass: '',
                    sortable: true
                },
            ];

            if (this.dynamicFields && this.dynamicFields.length) {
                const dynamicFields = this.dynamicFields.filter(field => field && field.options && field.options.display_as_table_column);

                dynamicFields.forEach((field) => {
                    fields.push(
                        {
                            key: field.transformed_name,
                            label: field.translation_key ? this.$t(field.translation_key).ucFirst() : field.name,
                            thClass: '',
                            sortable: false
                        },
                    )
                })
            }

            fields.push(
                {
                    key: "actions",
                    label: this.$t('base.actions').ucFirst(),
                    thClass: '',
                    tdClass: '',
                    sortable: false
                }
            )

            return fields;
        },

        createLink: function () {
            return { name: this.type === 'my' ? 'my-partner-create' : 'partner-create', params: {type: this.$t('partners::partners.types.' + this.type)} };
        },
    },

    data() {
        return {};
    },

    methods: {
        getIt: function (item, callback) {
            if (!item.users) {
                item.users = [this.authenticated.id]
            } else if (Array.isArray(item.users)) {
                item.users.push(this.authenticated.id)
            }

            this.$store.dispatch('partner/update', Object.assign({with: ['fields', 'fieldValues', 'fieldValues.fieldVersion'], main_user: this.authenticated.id}, item)).then(() => {
                if (typeof callback === 'function') {
                    callback()
                }
            })
        },
    },

    created() {
        this.$store.dispatch('field/list', { owner_type: 'Appon\\Partners\\Models\\Partner', with: 'fieldType' })
    },

    mounted() {
        this.$store.dispatch('app/title', {key: 'partners::partners.partner', count: 2})
    },

    unmounted() {
        this.$store.dispatch('app/title', '')
    }
};
</script>

<template>
    <advanced-table
        :caption="$tc('partners::partners.partner', 2).ucFirst()"
        :create-link="createLink"
        :current-page="1"
        :edit-link="{ name: 'partner-edit', params: {type: $route.params.type} }"
        :empty-filtered-text="$t('base.empty_filtered').ucFirst()"
        :empty-text="$t('base.empty_table').ucFirst()"
        :fields="fields"
        :per-page="10"
        :queries="{ type: type }"
        :to-be-forwarded="{ type: $t('partners::partners.types.' + type) }"
        borderless
        :deletable="type !== 'free' || admin"
        editable
        hover
        moduleNamespace="partner"
        outlined
        permission="partners"
        primary-key="id"
        responsive="sm"
        searchable
        show-empty
        sort-by="name"
        sort-direction="asc"
        sort-icon-left
    >
        <template #more-action="{ item, fetch }">
            <li v-if="type === 'free'" class="list-inline-item">
                <a
                    v-if="can('partners.update')"
                    @click.prevent="getIt(item, fetch)"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :title="$t('base.add_it_to_mine').ucFirst()"
                    class="px-2 text-info cursor-pointer"
                ><i class="bx bx-user-plus font-size-18"></i
                ></a>
            </li>
        </template>
        <template #extra-actions="{ item }">
                <span :id="item.name" class="dropdown-item cursor-pointer">
                    <i class="mdi mdi-plus-box-multiple-outline font-size-14 me-2"></i>
                    <span>Extra action</span>
                </span>
        </template>
    </advanced-table>
</template>
